



































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as Annotorious from '@recogito/annotorious';

import '@recogito/annotorious/dist/annotorious.min.css';

import LightBoxImage from '@/mixins/LightBoxImage';

import {
  Redaction as IRedaction,
  Comments as IComments
} from '../../../../interfaces/IRedactionPreview';

@Component({
  mixins: [LightBoxImage]
})
export default class ContentRedaction extends Vue {
  @Prop({ required: true }) redaction!: IRedaction;

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  get setImageRedaction() {
    if (!this.redaction?.url) return '';

    return `https://explicae.com.br/app/${this.redaction.url}`;
  }

  hasContentHTML(content: string) {
    return content === '<p><br></p>' ? false : !!content;
  }

  setCommentsRedaction() {
    const imgRedaction: any = document.getElementById('img-redaction');

    if (this.redaction?.url) {
      const config = {
        image: imgRedaction,
        readOnly: true
      };

      const anno = Annotorious.init(config);
      const annotations: Array<IComments> | undefined = this.redaction.comments;

      const width = imgRedaction?.naturalWidth;
      const height = imgRedaction?.naturalHeight;

      if (annotations) {
        annotations.forEach((annotation: IComments, index) => {
          const x = this.calculatePixels(Number(annotation.x), width);
          const y = this.calculatePixels(Number(annotation.y), height);
          const w = this.calculatePixels(Number(annotation.width), width);
          const h = this.calculatePixels(Number(annotation.height), height);

          anno.addAnnotation({
            '@context': 'http://www.w3.org/ns/anno.jsonld',
            id: index,
            type: 'Annotation',
            body: [
              {
                type: 'TextualBody',
                value: annotation.text
              }
            ],
            target: {
              selector: {
                type: 'FragmentSelector',
                conformsTo: 'http://www.w3.org/TR/media-frags/',
                value: `xywh=pixel:${x},${y},${w},${h}`
              }
            }
          });
        });
      }

      anno.on('mouseEnterAnnotation', (annotation: any) => {
        anno.selectAnnotation(annotation);
        anno.setVisible(true);
      });

      anno.on('mouseLeaveAnnotation', () => {
        anno.cancelSelected();
      });
    }
  }

  calculatePixels(percentage: number, pixels: number) {
    return pixels * percentage;
  }
}
