
import {
  Component, Mixins, Prop, Watch 
} from 'vue-property-decorator';
import { Line } from 'vue-chartjs';

interface Competence {
  title: string;
  avg_users: string | number;
  avg_me: string | number;
}

type competenceKey = 'avg_users' | 'avg_me';

const MULTIPLIER_COMPETENCES = 40;

@Component({
  extends: Line
})
export default class LineChartAll extends Mixins(Line) {
  @Prop() data!: [];
  @Prop({ default: 'top' }) positionLegend!: Chart.PositionType;

  private chartColors = this.getColors();

  mounted() {
    this.initRenderChart();
  }

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  @Watch('isDarkMode')
  updateColorsChart() {
    this.chartColors = this.getColors();
    this.initRenderChart();
  }

  initRenderChart() {
    this.renderChart(
      {
        labels: ['1', '2', '3', '4', '5'],
        datasets: [
          {
            label: 'Você',
            data: this.setData('avg_me'),
            pointBackgroundColor: this.chartColors.primaryColorPure,
            borderColor: this.chartColors.primaryColorPure,
            borderWidth: 2,
            backgroundColor: 'transparent'
          },
          {
            label: 'Galera',
            data: this.setData('avg_users'),
            pointBackgroundColor: this.chartColors.feedbackColorWarningDark,
            borderColor: this.chartColors.feedbackColorWarningDark,
            borderWidth: 2,
            backgroundColor: 'transparent'
          }
        ]
      },
      {
        legend: {
          display: true,
          position: this.positionLegend,
          align: 'center',
          labels: {
            usePointStyle: true,
            boxWidth: 8,
            fontStyle: '500',
            fontColor: this.chartColors.neutralColorLowPure,
            padding: 20
          }
        },
        title: {
          display: true,
          text: 'Notas por competência',
          fontColor: this.chartColors.neutralColorLowPure,
          fontStyle: '500'
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: 200,
                min: 0,
                stepSize: 40,
                fontColor: this.chartColors.neutralColorLowPure
              },
              gridLines: {
                display: true,
                color: this.chartColors.neutralColorLowLight
              },
              scaleLabel: {
                display: true,
                labelString: 'Notas',
                fontColor: this.chartColors.neutralColorLowPure,
                fontStyle: '500'
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                fontColor: this.chartColors.neutralColorLowPure
              },
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: true,
                labelString: 'Competências',
                fontColor: this.chartColors.neutralColorLowPure,
                fontStyle: '500'
              }
            }
          ]
        }
      }
    );
  }

  setData(keyValue: competenceKey) {
    const data = this.data.map((competence: Competence, index: number) => ({
      x: index + 1,
      y: Math.round(Number(competence[keyValue]) * MULTIPLIER_COMPETENCES)
    }));

    return data;
  }

  getColors() {
    return {
      primaryColorPure: getComputedStyle(document.documentElement).getPropertyValue(
        '--primarycolor-pure'
      ),
      feedbackColorWarningDark: getComputedStyle(document.documentElement).getPropertyValue(
        '--feedbackcolor-warning-dark'
      ),
      neutralColorLowPure: getComputedStyle(document.documentElement).getPropertyValue(
        '--neutralcolor-low-pure'
      ),
      neutralColorLowLight: getComputedStyle(document.documentElement).getPropertyValue(
        '--neutralcolor-low-light'
      )
    };
  }
}
