






































import { Component, Vue } from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';

@Component({
  components: {
    ExLoading
  }
})
export default class CorrectionRedactionLoading extends Vue {}
